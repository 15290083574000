import React, { useState, useEffect, lazy } from "react";
import Cookies from "js-cookie";
import {
  AppBar,
  Box,
  CssBaseline,
  Grid,
  Skeleton,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import { SET_MENU } from "store/actions";
import ProjectDetailContent from "component/ProjectDetailContent";
import { useParams } from "react-router-dom";
import { getProjectById } from "api/index";
import Loadable from "utilities/Loadable";
import ProjectDetailSearchBar from "component/ProjectDetailSearchBar";
import { useAuth0 } from "@auth0/auth0-react";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

const ProjectDetailMapComponent = Loadable(
  lazy(() => import("component/ProjectDetailMapComponent")),
);

// styles
const Main = styled("main", {
  shouldForwardProp: (prop) =>
    prop !== "open" && prop !== "theme" && prop !== "compactProjectDetail",
})(({ theme, open, compactProjectDetail }) => {
  return {
    borderBottomLeftRadius: 0,
    width: "100%",
    backgroundColor: "transparent !important",
    borderBottomRightRadius: 0,
    transition: theme.transitions.create(
      "margin",
      open
        ? {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }
        : {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          },
    ),
    [theme.breakpoints.up("md")]: {
      width: compactProjectDetail ? `calc(2/3 * 100%)` : `calc(1/3 * 100%)`,
    },

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  };
});

const ProjectDetailsLayout = ({ children, ...props }) => {
  const theme = useTheme();
  const { uniqueId } = useParams();
  const token = localStorage.getItem("access_token");
  const [loading, setLoading] = useState();
  const [fetchedData, setFetchedData] = useState({});
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [compactProjectDetail, setProjectDetailSize] = useState(false);
  const { logout } = useAuth0();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const tour_completed = Cookies.get("tour_completed");
  // const {  joyrideState, setStepReadyState } = useJoyride();

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    Cookies.remove("access_token");
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  const fetchProjectData = () => {
    setLoading(true);

    getProjectById(uniqueId, token) // Use the projectId obtained from the URL
      .then((response) => {
        const { data } = response.data;
        setFetchedData(data);
        if (data?.geometry) {
          setLoading(false);
          const geoJsonData = data.geometry;
          setGeoJsonData(geoJsonData);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
        setFetchedData(null);
        setGeoJsonData(null);
        setLoading(true);
      });
  };

  useEffect(() => {
    fetchProjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Include projectId in the dependency array so that the effect re-runs when the parameter changes

  const triggerTour = () => {
    localStorage.removeItem("selectedProject");
  };

  return (
    <>
      {loading ? (
        <Backdrop
          sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
          open={loading}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      ) : (
        <div
          style={{ position: "relative", height: "100vh", overflow: "auto" }}
        >
          <CssBaseline />
          <AppBar
            enableColorOnDark
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{
              bgcolor: theme.palette.background.default,
              transition: leftDrawerOpened
                ? theme.transitions.create("width")
                : "none",
              borderBottom: "1.5px solid #164A1A",
              background: "#F9F5EB",
              padding: 0,
              top: 0,
              zIndex: 9999,
              height: "56px",
              width: "100%",
            }}
          >
            <Header triggerTour={triggerTour} />
          </AppBar>
          <Box
            className="Help-projectpage-start"
            sx={{
              display: "flex",
              position: "relative",
              height: isSmall || isMedium ? "auto" : "calc(100vh - 56px)",
              marginTop: "56px",
            }}
          >
            <ProjectDetailContent
              fetchedData={fetchedData}
              compactProjectDetail={compactProjectDetail}
            >
              <ProjectDetailSearchBar
                handleLeftDrawerToggle={handleLeftDrawerToggle}
                projectData={fetchedData}
                {...props}
              />
            </ProjectDetailContent>

            {!isSmall && !isMedium && (
              <Main
                theme={theme}
                open={leftDrawerOpened}
                compactProjectDetail={compactProjectDetail}
              >
                {fetchedData ? (
                  // Render ProjectDetailMapComponent when fetchedData is available
                  <ProjectDetailMapComponent
                    mapData={fetchedData}
                    layerGeoJson={geoJsonData}
                    compactProjectDetail={compactProjectDetail}
                    setProjectDetailSize={setProjectDetailSize}
                    {...props}
                  />
                ) : (
                  // Render error message or alternative content when fetchedData is null
                  <Skeleton variant="rect" width="100%" height="100vh" />
                )}
              </Main>
            )}
          </Box>
          <Footer />
        </div>
      )}
    </>
  );
};
export default ProjectDetailsLayout;
