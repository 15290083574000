import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation } from 'react-router-dom';
import {
  Grid,
  Typography,
  Chip,
  Paper,
  Button,
  Box,
  Divider,
  Card,
  Link,
  CardHeader,
  CardContent,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import tooltipCalender from "../../assets/images/icons/tooltip-clender.svg";
import closeTooltip from "../../assets/images/icons/close-tooltip.svg";
import infoIcon from "../../assets/images/icons/infobuttonicon.svg";
import ImageCarousel from "../ImageCarousel";
import infoPoupIcon from "../../assets/images/icons/infobuttonicon.svg";
import mapStroke from "../../assets/images/icons/mapstroke.svg";
import sizeimg from "../../assets/images/icons/sizeimg.svg";
import StatelliteIcon from "../../assets/images/icons/Satellite.svg";
import CameraIcon from "../../assets/images/icons/Photo.svg";
import SensorIcon from "../../assets/images/icons/Sensor.svg";
import CloseButtonIcon from "assets/images/icons/closebutton.svg";
import CalendarIcon from "assets/images/icons/Calendar.svg";
import MapIcon from "assets/images/icons/Pin.svg";

import "./popover.css";

import Waveform from "./waveform";

import { fetchWithRetry, updateResource } from "component/ProjectDetailContent/ProjectDetailCarousel";

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#EAFFD8",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    background: "#EAFFD8",
    color: "#2B2B2B",
    width: "1000px",
    boxShadow: "4px 20px 36px 8px #1D1D1B14",
    border: "1px solid #164A1A",
    padding: 0,
  },
}));


function ProjectPreviewContent({
  onClick,
  loading,
  wildlife_visual_resources,
  onOpen,
  handlePopoverContentClose,
  selectedProject,
}) {
  const {
    identifier = '',
    name = null,
    countries = [],
    client = '',
    sdgs = [],
    homepage = '',
    short_abstract = '',
    be_product = '',
    tags = [],
    project_type = '',
    wildlife_audio_resources = [],
    metrics = {},
    site_area_formated_str = '',
    number_project_sites = 0,
    project_start = '2019-01-01'
  } = selectedProject || {};


  const [selectedTrack, _setSelectedTrack] = useState([]);
  const [isLoadingAudio, _setIsLoadingAudio] = useState(false);
  const [anchorEl, setAnchorEl] = useState(
    new Array(metrics?.length).fill(null),
  );

  const location = useLocation();


  const selectedTrackRef = useRef(selectedTrack);
  const setSelectedTrack = (t) => {
    selectedTrackRef.current = t;
    _setSelectedTrack(t);
  }

  const isLoadingAudioRef = useRef(isLoadingAudio);
  const setIsLoadingAudio = (a) => {
    isLoadingAudioRef.current = a;
    _setIsLoadingAudio(a);
  }

  // Ref for the parent grid
  const gridRef = useRef(null);

  // Ref for the project title container
  const titleRef = useRef(null);

  //const [isTooltipOpen, setIsTooltipOpen] = useState(false); // State to manage tooltip visibility

  /*  const [testWildlifeAudio, setTestWildlifeAudio] = useState(null); */
  const accessToken = localStorage.getItem("access_token");

  const handlePopoverOpen = (index, event) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = event.currentTarget;
    setAnchorEl(newAnchorEl);
  };

  const handlePopoverClose = (index) => {
    const newAnchorEl = [...anchorEl];
    newAnchorEl[index] = null;
    setAnchorEl(newAnchorEl);
  };

  const fetchAudioData = useCallback(async () => {
    setIsLoadingAudio(true);
    setSelectedTrack([...wildlife_audio_resources.map(a => { return {...a, type: "audio", loading: true} })]);

    if (wildlife_audio_resources.length === 0 && isLoadingAudioRef.current) setIsLoadingAudio(false);
    try {
      for (const resource of wildlife_audio_resources) {

        let response;
        try {
          response = await fetchWithRetry(resource.access_url,accessToken);

        } catch (error) {
          console.error(
            `Error fetching data from ${resource.access_url}:`,
            error,
          );
        }

        const blob = await response.blob();
        const audioURL = URL.createObjectURL(blob);

        setSelectedTrack(prevData => updateResource([...prevData], resource.identifier, {
          access_url: audioURL,
          loading: false
        }));
        if(isLoadingAudioRef.current) setIsLoadingAudio(false);

      }
    } catch (error) {
      setIsLoadingAudio(true);
      console.error("Error fetching image data:", error);
    }
  },[wildlife_audio_resources,accessToken]);




  useEffect(() => {
    if (
      accessToken &&
      wildlife_audio_resources &&
      wildlife_audio_resources.length
    ) {
      fetchAudioData();
    }
  }, [wildlife_audio_resources,accessToken,fetchAudioData]);


  // Effect to adjust tooltip width based on parent grid width
  useEffect(() => {
    const gridWidth = gridRef.current ? gridRef.current.offsetWidth : 0; // Check if gridRef.current is not null
    const tooltip = document.querySelector(`.${tooltipClasses.tooltip}`);
    if (tooltip) {
      tooltip.style.width = `${gridWidth}px`; // Set tooltip width to match parent grid width
    }
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
          handlePopoverContentClose();
        }
    };

    if (onOpen) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
        document.removeEventListener('keydown', handleKeyDown);
    }

    // Cleanup event listener on unmount
    return () => {
        document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onOpen,handlePopoverContentClose]);

  const readMore = (value) => {

    if(process.env.REACT_APP_ORIGIN === 'http://localhost:3000/'){
      return `${process.env.REACT_APP_ORIGIN}pages/glossary/#${value}`
    } else {
      return `${process.env.REACT_APP_ORIGIN}/pages/glossary/#${value}`
    }
  }

  useEffect(() => {
    if(titleRef.current) {
      let btn = document.getElementById("view-project-btn");

      let topPixels =  titleRef.current ? `${titleRef.current.clientHeight + 61}px` : "61px";

      if (btn) btn.style.top = topPixels;
    }
  }, [loading])


  return (
    <>
      <Grid
        container
        display="flex"
        direction="column"
        style={{
          overflow: "hidden auto",
          background: "#f9f5eb",
          height: "100%",
          flexWrap: "nowrap",
        }}
        className="Help-entrypage-preview"
      >
        <Grid
          container
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            position: "sticky",
            top: "0",
            zIndex: 9,
            background: "#f9f5eb",
          }}
        >
          <Grid item xs={10} />
          <div
            style={{ cursor: "pointer", margin: "1rem" }}
            onClick={handlePopoverContentClose}
          >
            <img src={CloseButtonIcon} alt="close-button-icon" />
          </div>
        </Grid>
        {loading ? (
          <Skeleton variant="rectangular" width="100%" height="8rem" />
        ) : (
          <>
            <Grid
              item
              ref={titleRef}
              sx={{
                height: "auto",
                paddingLeft: "20px",
                paddingRight: "16px",
                position: "sticky",
                top: "61px",
                zIndex: 9,
                background: "#f9f5eb",
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontFamily: `'Cormorant Garamond', serif`,
                  fontSize: "38px",
                  fontWeight: "600",
                  lineHeight: "46px",
                  letterSpacing: "0px",
                  textAlign: "left",
                  color: "#2B2B2B",
                  paddingBottom: "24px",
                }}
              >
                {name ? name : <Skeleton variant="rectangular" width="100%" height="8rem" />}
              </Typography>
              {selectedProject.status !== undefined && 
                <div>Please check with the dashboard administrator for project permissions.</div>
              }
            </Grid>
            <Grid
              item
              sx={{
                height: "auto",
                marginLeft: "20px",
                marginRight: "16px",
              }}
            >
              {countries && countries.length > 0 && (
                <Typography
                  variant="subtitle1"
                  sx={{
                    paddingBottom: "0.5rem",
                    fontWeight: 300,
                    color: "#626364",
                  }}
                >
                  Country:
                  {countries.length === 1
                    ? ` ${countries[0]}`
                    : countries.map((country, index) => (
                        <span key={index} style={{ fontWeight: "300" }}>
                          {index === 0 ? country : `, ${country}`}
                        </span>
                      ))}
                </Typography>
              )}
              {project_type && (
                <Typography
                  variant="subtitle1"
                  sx={{
                    paddingBottom: "0.5rem",
                    fontWeight: 300,
                    color: "#626364",
                  }}
                >
                  {Array.isArray(project_type) && project_type.length > 0 && (
                    <>Project Type:</>
                  )}
                  {Array.isArray(project_type) &&
                    (project_type.length === 1
                      ? `, ${project_type[0]}`
                      : project_type.map((project, index) => (
                          <span key={index} style={{ fontWeight: "300" }}>
                            &nbsp; &nbsp; {index === 0 ? " " : ", "}, {project}
                          </span>
                        )))}
                </Typography>
              )}
              {client && (
                <Typography
                  variant="subtitle1"
                  sx={{
                    paddingBottom: "0.5rem",
                    fontWeight: 300,
                    color: "#626364",
                  }}
                >
                  <>Client:</> &nbsp; &nbsp;
                  <a
                    href={homepage}
                    style={{
                      color: "#626364",
                      fontWeight: 300,
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.textDecoration = "underline";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.textDecoration = "none";
                    }}
                  >
                    {client}
                  </a>
                </Typography>
              )}
            </Grid>
          </>
        )}

        <Grid
          item
          sx={{
            height: "auto",
            marginLeft: "20px",
            marginRight: "16px",
          }}
        >
          {selectedProject.status === undefined && (
            <>
              {loading ? (
              Array.from(new Array(3)).map((_, index) => (
                <Skeleton
                  key={index}
                  animation="wave"
                  variant="rectangular"
                  width={80}
                  height={32}
                />
              ))
              ) : (
                <Grid
                  item
                  style={{
                    display: "flex",
                    gap: "15px",
                    margin: "1rem 0rem",
                    marginTop: "1rem",
                    height: "auto",
                  }}
                >
                  <img src={StatelliteIcon} alt="StatelliteIcon" />
                  <img src={CameraIcon} alt="StatelliteIcon" />
                  <img src={SensorIcon} alt="StatelliteIcon" />
                </Grid>
              )}
            </>)}

          {tags && (
            <Grid
              item
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "8px",
                margin: "1rem 0rem",
                marginTop: "1rem",
                height: "auto",
              }}
            >
              {loading ? (
                Array.from(new Array(3)).map((_, index) => (
                  <Skeleton
                    key={index}
                    animation="wave"
                    variant="rectangular"
                    width={80}
                    height={32}
                  />
                ))
              ) : (
                <>
                  {tags &&
                    tags?.map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        variant="outlined"
                        size="large"
                        sx={{
                          borderColor: "#1A5C1F",
                          color: "#1A5C1F",
                          borderRadius: "5px",
                          fontFamily: "Source Sans 3",
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "17px",
                          letterSpacing: 0,
                        }}
                      />
                    ))}
                </>
              )}
            </Grid>
          )}
        </Grid>
        <div id="view-project-btn" style={{
          zIndex: 9,
          background: "#f9f5eb",
          position: "sticky",
          top: titleRef.current ? `${titleRef.current.clientHeight + 61}px` : "61px",
        }}>
          <Grid
            item
            sx={{
              height: "auto",
              marginLeft: "20px",
              marginRight: "16px",
              marginBottom: "28px",
            }}
          >
            {loading ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={80}
                height={32}
              />
            ) : (
                <Grid item>
                  {identifier && selectedProject.status === undefined ? (
                    <Button
                      component={Link}
                      id="view-project-button"
                      sx={{
                        mx: "auto",
                        my: 2,
                        py: 2,
                        width: "152px",
                        borderRadius: "50px",
                        textTransform: "uppercase",
                        fontWeight: 300,
                        background: "#164A1A",
                        '&:hover': {
                          background: "#062308",
                        },
                      }}
                      variant="contained"
                      color="primary"
                      onClick={onClick}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 400,
                          lineHeight: "19px",
                          color: "#F9F5EB",
                          letterSpacing: "0.04em",
                        }}
                      >
                        View Project{" "}
                      </Typography>
                    </Button>
                  ) : null}
                </Grid>
            )}
          </Grid>
          <div
            style={{
              border: "1px solid #D8D5CC",
            }}
          />
        </div>
        {selectedProject.status === undefined ? 
          <Grid
            container
            sx={{
              background: "#f9f5eb",
              paddingBottom: "2rem",
            }}
          >
            {loading ? (
              <Skeleton animation="wave" variant="text" width={80} height={32} />
            ) : (
              metrics?.length > 0 && (
                <Grid
                  sx={{
                    flexGrow: 1,
                    width: "100%",
                    position: "relative",
                    marginBottom: "1rem",
                    marginTop: "0",
                  }}
                  container
                  spacing={2}
                  ref={gridRef}
                >
                  <Grid item xs={12}>
                    <Grid
                      container
                      justifyContent="flex-start"
                      sx={{ margin: "0px 11px", paddingRight: "21px" }}
                    >
                      {metrics.map((value, index) => {
                        return (
                          <Grid key={index} item xs={3}>
                            <Paper
                              sx={{
                                height: 180,
                                width: 96,
                                position: "relative",
                                margin: "10px",
                                boxShadow: "2px 12px 32px 2px #1D1D1B0D",
                                backgroundColor: (theme) =>
                                  theme.palette.mode === "dark"
                                    ? "#1A2027"
                                    : "#F9F5EB",
                              }}
                            >
                              <div style={{ paddingTop: "0.5rem" }}>
                                <span
                                  style={{
                                    display: "block",
                                    marginTop: "1rem",
                                    marginBottom: "1rem",
                                    paddingTop: "10px",
                                  }}
                                >
                                  <img
                                    src={value.resources.access_url}
                                    alt={value.resources.identifier}
                                    style={{
                                      display: "block",
                                      margin: "auto",
                                      width: value.resources.access_url.endsWith(
                                        "/BLANK",
                                      )
                                        ? "60%"
                                        : "50px",
                                      filter:
                                        "invert(20%) sepia(66%) saturate(510%) hue-rotate(48deg) brightness(95%) contrast(96%)",
                                    }}
                                  />
                                </span>
                                <Typography
                                  style={{
                                    fontSize: "18px",
                                    color: "#164A1A",
                                    fontWeight: 500,
                                    marginBottom: 0,
                                    textTransform: "capitalize",
                                    textAlign: "center",
                                  }}
                                >
                                  {value.formatted_value}
                                </Typography>
                                <Typography
                                  style={{
                                    fontFamily: "MADE TOMMY",
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    lineHeight: "14px",
                                    letterSpacing: 0,
                                    color: "#626364",
                                    padding: "10px",
                                    marginTop: "5px",
                                    textAlign: "center",
                                    textTransform: "capitalize",
                                    overflowWrap: "anywhere",
                                  }}
                                >
                                  {value?.formatted_unit}
                                </Typography>
                              </div>

                              <CustomTooltip
                                arrow
                                placement="top"
                                title={
                                  <Card
                                    sx={{
                                      height: "100%",
                                      background: "#EAFFD8",
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    <CardHeader
                                      sx={{ padding: "1rem" }}
                                      subheader={
                                        <Typography
                                          variant="h4"
                                          sx={{
                                            fontFamily: "MADE TOMMY",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            lineHeight: "17px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                            color: "#2B2B2B",
                                            paddingBottom: "1rem",
                                          }}
                                        >
                                          {value.resources.caption_text}
                                        </Typography>
                                      }
                                      action={
                                        <IconButton
                                          disableFocusRipple
                                          disableTouchRipple
                                          disableRipple
                                          size="small"
                                          aria-label="close"
                                          onClick={() =>
                                            handlePopoverClose(index)
                                          }
                                        >
                                          <img src={closeTooltip} alt="close" />
                                        </IconButton>
                                      }
                                    ></CardHeader>
                                    <Divider
                                      sx={{ border: "1px solid #164A1A" }}
                                      variant={"fullWidth"}
                                    />
                                    <CardContent sx={{ padding: "1rem" }}>
                                      <div style={{ marginBottom: "15px" }}>
                                        <Typography
                                          variant="body1"
                                          sx={{
                                            fontFamily: "MADE TOMMY",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            lineHeight: "14px",
                                            letterSpacing: "0px",
                                            textAlign: "left",
                                            paddingBottom: "1rem",
                                          }}
                                        >
                                          {" "}
                                          Function{" "}
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontFamily: "MADE TOMMY",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {value.resources.description}
                                        </Typography>
                                      </div>
                                      <div style={{ padding: "0.5rem 0rem" }}>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            color: "#2B2B2B",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          <span
                                            sx={{
                                              color: "#2B2B2B",
                                              fontSize: "14px",
                                              fontWeight: "500",
                                              marginLeft: "1rem",
                                            }}
                                          >
                                            {" "}
                                            Reporting period{" "}
                                          </span>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            &nbsp; &nbsp; &nbsp;
                                            <img
                                              src={tooltipCalender}
                                              alt="calendar"
                                              width="16px"
                                              height="16px"
                                            />
                                            &nbsp;
                                            <span
                                              sx={{
                                                color: "#2B2B2B",
                                                fontSize: "14px",
                                                fontWeight: "500",
                                              }}
                                            >
                                              {" "}
                                              {value.formatted_timestamp}
                                            </span>
                                          </div>
                                        </div>
                                        {
                                          value.glossary_resource &&
                                          <Typography
                                            variant="body1"
                                            sx={{ marginTop: '1rem' }}
                                          >
                                            <a href={readMore(value.glossary_resource)} target="_blank" rel="noreferrer" style={{ color: '#164A1A', textDecoration: 'underline', fontWeight: 500, fontSize: '14px' }}>Read more</a>
                                          </Typography>

                                        }
                                      </div>
                                    </CardContent>
                                  </Card>
                                }
                              >
                                <IconButton
                                  disableRipple
                                  disableFocusRipple
                                  disableTouchRipple
                                  aria-owns={`${index}-mouse-over-popover`}
                                  aria-haspopup="true"
                                  onMouseEnter={(event) =>
                                    handlePopoverOpen(index, event)
                                  }
                                  onMouseLeave={() => handlePopoverClose(index)}
                                  sx={{
                                    position: "absolute",
                                    top: "0px",
                                    right: "0px",
                                  }}
                                >
                                  <img
                                    src={infoIcon}
                                    alt="icon"
                                    style={{ width: "20px", height: "20px" }}
                                  />
                                </IconButton>
                              </CustomTooltip>
                            </Paper>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              )
            )}

            {short_abstract && (
              <Grid
                item
                sx={{
                  paddingTop: "1.5rem",
                  paddingBottom: "0",
                  height: "auto",
                  width: "100%",
                  margin: "0px 21px",
                }}
              >
                {loading ? (
                  <Skeleton variant="text" width="100" height={20} />
                ) : (
                  <Typography variant="h5">About this project</Typography>
                )}
                {loading ? (
                  <Skeleton variant="text" width="100%" height={20} />
                ) : (
                  <>
                    <Grid container>
                      <Grid item sx={{ paddingRight: "20px" }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "15px",
                          }}
                        >
                          <img src={sizeimg} alt="ruler" />
                          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                            <Typography
                              color={"#164A1A"}
                              sx={{
                                fontSize: "16px",
                                fontWeight: "300",
                                lineHeight: "19.84px",
                                letterSpacing: "0px",
                              }}
                            >
                              &nbsp;{site_area_formated_str}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item sx={{ paddingRight: "20px" }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            paddingTop: "15px",
                          }}
                        >
                          <img src={CalendarIcon} alt="calendar" />
                          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                            <Typography
                              color={"#164A1A"}
                              sx={{
                                fontSize: "16px",
                                fontWeight: "300",
                                lineHeight: "19.84px",
                                letterSpacing: "0px",
                              }}
                            >
                              &nbsp;Since {project_start.split('-')[0]}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid container>
                        <Grid item sx={{ paddingRight: "20px" }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              paddingTop: "15px",
                            }}
                          >
                            <img src={MapIcon} alt="pin" />
                            <Box
                              sx={{ display: "flex", alignItems: "flex-start" }}
                            >
                              <Typography
                                color={"#626364"}
                                sx={{
                                  color: "#164A1A",
                                  fontFamily: "MADE TOMMY",
                                  fontSize: "16px",
                                  fontWeight: "300",
                                  lineHeight: "19.84px",
                                  letterSpacing: "0px",
                                  textAlign: "left",
                                }}
                              >
                                &nbsp;{number_project_sites} sites
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item sx={{ paddingRight: "20px" }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              paddingTop: "15px",
                              paddingLeft: "20px",
                            }}
                          >
                            <img src={mapStroke} alt="map strokes" />
                            <Box
                              sx={{ display: "flex", alignItems: "flex-start" }}
                            >
                              <Typography
                                color={"#626364"}
                                sx={{
                                  color: "#164A1A",
                                  fontFamily: "MADE TOMMY",
                                  fontSize: "16px",
                                  fontWeight: "300",
                                  lineHeight: "19.84px",
                                  letterSpacing: "0px",
                                  textAlign: "left",
                                }}
                              >
                                &nbsp;{be_product}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Typography
                      mb={2}
                      mt={4}
                      style={{
                        fontSize: "16px",
                        letterSpacing: "0px",
                        color: "#164A1A",
                        paddingRight: "2.5rem",
                        lineHeight: "19.84px",
                      }}
                    >
                      {short_abstract}
                    </Typography>
                  </>
                )}
                {loading ? (
                  <Skeleton variant="text" width="100%" height={20} />
                ) : (
                  <Typography
                    variant="body2"
                    mb={1}
                    style={{
                      color: "#2B2B2B",
                      letterSpacing: 0,
                      lineHeight: "19px",
                      fontWeight: 400,
                      fontFamily: `'Source Sans 3', sans-serif`,
                      fontStyle: "italic",
                      fontSize: "15px", //styleName: Large/Other/Citation;
                    }}
                  >
                    – written by ‘{client}’
                  </Typography>
                )}
              </Grid>
            )}

            {sdgs && sdgs.length > 0 && (
              <Grid
                item
                style={{
                  paddingBottom: "1rem",
                  width: "100%",
                  margin: "0px 21px",
                }}
              >
                {loading ? (
                  <>
                    <Typography variant="h3" style={{ padding: "1rem 0rem" }}>
                      <Skeleton variant="text" width="80%" height={20} />
                    </Typography>
                    <Grid
                      container
                      display="flex"
                      direction="row"
                      justifyContent="space-between"
                    >
                      {Array.from(new Array(sdgs.length)).map((_, index) => (
                        <Grid item xs={3} key={index}>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={100}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Typography
                      variant="h5"
                      style={{ padding: "24px 0px 15px 0px" }}
                    >
                      Sustainable development goals{" "}
                      {/* <img
                        src={infoPoupIcon}
                        alt="info"
                        width="20px"
                        height="20px"
                        style={{ verticalAlign: "middle" }}
                      /> */}
                    </Typography>

                    <Grid
                      container
                      display="flex"
                      direction="row"
                      justifyContent="start"
                    >
                      {sdgs.map((image, index) => (
                        <div key={index} style={{ marginRight: "10px", marginBottom: "5px" }}>
                          <a
                            href={image.link}
                            target="_blank"
                            style={{ height: "48px", display: "block" }} rel="noreferrer"
                          >
                            <img
                              src={image.icon}
                              alt={image.name}
                              width="48"
                              height="48"
                              style={{ borderRadius: "2px" }}
                            />
                          </a>
                        </div>
                      ))}
                    </Grid>
                  </>
                )}
              </Grid>
            )}

            <Divider sx={{ borderColor: "#D8D5CC", width: "100%", marginTop: "1.5rem"}} />
            {/*   database  */}
            <Grid item style={{ padding: "3rem 0rem", margin: "0px 21px" }}>
              {loading ? (
                <>
                  <Typography variant="h3">
                    <Skeleton variant="text" width="80%" height={20} />
                  </Typography>
                  <Grid
                    container
                    display="flex"
                    direction="row"
                    spacing={2}
                    alignItems="center"
                  >
                    <div
                      style={{
                        backgroundColor: "rgba(0, 123, 255, 0.2)",
                        padding: "8px",
                        borderRadius: "8px",
                        marginLeft: "1rem",
                      }}
                    >
                      <IconButton
                        size="small"
                        aria-label="close"
                        style={{ background: "rgba(22, 74, 26, 0.5)" }}
                      >
                        <Skeleton variant="circular" width={20} height={20} />
                      </IconButton>
                    </div>
                    <Typography variant="h5" style={{ margin: "0rem 1rem" }}>
                      <Skeleton variant="text" width="80%" height={20} />
                    </Typography>
                  </Grid>
                </>
              ) : (
                <Grid item>
                  {wildlife_visual_resources?.length > 0 && (
                    <ImageCarousel
                      country={
                        countries.length === 1
                          ? ` ${countries[0]}`
                          : countries.map((country, index) => (
                              <span key={index} style={{ fontWeight: "300" }}>
                                {index === 0 ? country : `, ${country}`}
                              </span>
                            ))
                      }
                      wildlife_visual_resources={wildlife_visual_resources}
                    />
                  )}

                  {isLoadingAudio === true ? (
                    // Skeleton loading UI while audio is loading
                    <Skeleton variant="rect" width={400} height={200} />
                  ) : wildlife_audio_resources &&
                    wildlife_audio_resources.length &&
                    selectedTrack?.length > 0 ? (
                    <Grid item>
                      <Typography variant="h5">
                        Listen to this Area
                        <img
                          src={infoPoupIcon}
                          alt="info"
                          width="20px"
                          height="20px"
                          style={{ verticalAlign: "middle", marginLeft: "1rem" }}
                        />
                      </Typography>
                      <div>
                        {/* Actual waveform component once audio is loaded */}
                        <Waveform tracks={selectedTrack} />
                      </div>
                    </Grid>
                  ) : (
                    <Grid sx={{ display: "flex", alignItems: "center" }}>
                      <img
                        src={infoPoupIcon}
                        alt="info"
                        width="20px"
                        height="20px"
                        style={{ verticalAlign: "middle", marginRight: ".5rem" }}
                      />
                      <Typography variant="subtitle1" sx={{ color: "#62636" }}>
                        No audio data available
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
          : null
        }
      </Grid>
    </>
  );
}

export default ProjectPreviewContent;