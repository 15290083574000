import {
  Grid,
  Typography,
  Chip,
  Paper,
  Box,
  TableContainer,
  Skeleton,
} from "@mui/material";

import ProjectDetailCarousel from "./ProjectDetailCarousel";
import Waveform from "component/ProjectPreviewContent/waveform";

import { AboutInfo } from "./commonContent";

import StatelliteIcon from "../../assets/images/icons/Satellite.svg";
import CameraIcon from "../../assets/images/icons/Photo.svg";
import SensorIcon from "../../assets/images/icons/Sensor.svg";
import ProjectSiteList from "./ProjectSite";
import infoPoupIcon from "../../assets/images/icons/infobuttonicon.svg";
import Pin from "../../assets/images/icons/Pin.svg";
import mapStroke from "../../assets/images/icons/mapstroke.svg";
import calender from "../../assets/images/icons/calender.svg";
import sizeimg from "../../assets/images/icons/sizeimg.svg";
import { useJoyride } from "utilities/JoyrideProvider";

const ProjectDetailTable = ({
  fetchedData,
  isLoadingAudio,
  selectedTrack,
  compactProjectDetail
}) => {
  const {
    abstract,
    client,
    contact_email,
    countries,
    homepage,
    number_project_sites,
    sites,
    project_types,
    sdgs,
    site_area_formated_str,
    tags,
    wildlife_visual_resources,
    be_product,
    project_start,
  } = fetchedData;

  const { joyrideState } = useJoyride();

  return (
    <TableContainer
      component={Paper}
      sx={{
        background: "transparent",
        borderRadius: "0",
        display: joyrideState.stepIndex >= 14 ? 'none' : '',
      }}
    >
          {/* Row 1 */}
          <Box sx={{ display: !compactProjectDetail ? 'flex' : 'block' }}>
            <Box
              align="left"
              style={{
                width: compactProjectDetail ? "100%" : "50%",
                borderRight: "1px solid #D8D5CC",
                borderBottom: "1px solid #D8D5CC",
                padding: "2rem 3rem",
              }}
            >
              {countries && countries.length > 0 && (
                <Typography
                  variant="subtitle1"
                  sx={{
                    paddingBottom: "0.5rem",
                    fontWeight: 300,
                    color: "#626364",
                  }}
                >
                  Country:
                  {countries.length === 1
                    ? ` ${countries[0]}`
                    : countries.map((country, index) => (
                        <span key={index} style={{ fontWeight: "300" }}>
                          {index === 0 ? country : `, ${country}`}
                        </span>
                      ))}
                </Typography>
              )}

              {contact_email && (
                <Typography
                  variant="subtitle1"
                  sx={{
                    paddingBottom: "0.5rem",
                    fontWeight: 300,
                    color: "#626364",
                  }}
                >
                  <>Contact Email: </>
                  <span style={{ fontWeight: "400" }}>
                    &nbsp; &nbsp; {homepage}
                  </span>
                </Typography>
              )}

              {client && (
                <Typography
                  variant="subtitle1"
                  sx={{
                    paddingBottom: "0.5rem",
                    fontWeight: 300,
                    color: "#626364",
                  }}
                >
                  <>Client:</> &nbsp; &nbsp;
                  <a
                    href={homepage}
                    style={{
                      fontWeight: "300",
                      textDecoration: "none",
                      cursor: "pointer",
                      color: "#626364",
                    }}
                    onMouseEnter={(e) => {
                      e.target.style.textDecoration = "underline";
                    }}
                    onMouseLeave={(e) => {
                      e.target.style.textDecoration = "none";
                    }}
                  >
                    {client}
                  </a>
                </Typography>
              )}
            </Box>
            <Box
              align="left"
              style={{
                width: compactProjectDetail ? "100%" : "50%",
                borderBottom: "1px solid #D8D5CC",
                padding: compactProjectDetail ? "1rem 1rem 2rem 3rem" : "1rem",
              }}
            >
              {project_types && (
                <Typography
                  variant="subtitle1"
                  sx={{
                    paddingBottom: "0.5rem",
                    fontWeight: 300,
                    color: "#626364",
                  }}
                >
                  {Array.isArray(project_types) && project_types.length > 0 && (
                    <>Project Type:</>
                  )}
                  {Array.isArray(project_types) &&
                    (project_types.length === 1
                      ? `, ${project_types[0]}`
                      : project_types.map((project, index) => (
                          <span key={index} style={{ fontWeight: "300" }}>
                            &nbsp; &nbsp; {index === 0 ? " " : ", "}, {project}
                          </span>
                        )))}
                </Typography>
              )}

              <Grid
                item
                style={{
                  display: "flex",
                  gap: "15px",
                  margin: "1rem 0rem",
                  marginTop: "1rem",
                  height: "auto"
                }}
              >
                <img src={StatelliteIcon} alt="StatelliteIcon" className="my-seventh-step"/>
                <img src={SensorIcon} alt="SensorIcon" className="my-eight-step"/>
                <img src={CameraIcon} alt="CameraIcon" className="my-ninth-step"/>
              </Grid>

              {tags && (
                <Grid
                  item
                  style={{
                    display: "flex",
                    gap: "8px",
                    margin: "1rem 0rem",
                    marginTop: "1rem",
                    height: "auto",
                    flexWrap: "wrap"
                  }}
                >
                  {tags &&
                    tags?.map((tag, index) => (
                      <Chip
                        key={index}
                        label={tag}
                        variant="outlined"
                        size="large"
                        sx={{
                          borderColor: "#1A5C1F",
                          color: "#1A5C1F",
                          borderRadius: "5px",
                        }}
                      />
                    ))}
                </Grid>
              )}
            </Box>
          </Box>

          {/* Row 2 */}
          <Box style={{ display: (!compactProjectDetail ? 'flex' : 'block') }}>
            <Box
              align="left"
              style={{
                width: compactProjectDetail ? "100%" : "50%",
                borderRight: "1px solid #D8D5CC",
                padding: "1rem 1rem 2rem 3rem",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
              }}
            >
              {abstract && (
                <Grid
                  item
                  sx={{
                    paddingTop: "1rem",
                    paddingBottom: "4rem",
                    height: "auto",
                  }}
                >
                  <Typography variant="h5" mb={4}>
                    About this project
                  </Typography>
                  <Grid container>
                    <div>
                      <AboutInfo img={sizeimg} paddingTop={"15px"}>
                        {site_area_formated_str}
                      </AboutInfo>
                      <AboutInfo img={Pin} paddingTop={"15px"}>
                        {number_project_sites} sites
                      </AboutInfo>
                    </div>
                    <div>
                      <AboutInfo img={calender} paddingTop={"15px"}>
                        Since {project_start.split('-')[0]}
                      </AboutInfo>
                      <AboutInfo img={mapStroke} paddingTop={"15px"}>
                        {be_product}
                      </AboutInfo>
                    </div>
                  </Grid>
                  <Typography
                    mb={2}
                    mt={4}
                    style={{
                      fontSize: "18px",
                      letterSpacing: "0px",
                      color: "#164A1A",
                      paddingRight: "2.5rem",
                      lineHeight: "19.84px",
                    }}
                  >
                    {abstract}
                  </Typography>
                  <Typography
                    variant="body2"
                    mb={5}
                    style={{
                      color: "#2B2B2B",
                      letterSpacing: 0,
                      lineHeight: "19.84px",
                      fontWeight: 400,
                      fontFamily: `'Source Sans 3', sans-serif`,
                      fontStyle: "italic",
                      fontSize: "15px", //styleName: Large/Other/Citation;
                    }}
                  >
                    – written by ‘{client}’
                  </Typography>
                </Grid>
              )}

              {sdgs && sdgs.length > 0 && (
                <Grid item style={{ paddingBottom: "2rem" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        padding: "1rem 0rem",
                        marginRight: ".5rem",
                      }}
                    >
                      Sustainable development goals
                    </Typography>
                    {/* <img
                      src={infoPoupIcon}
                      alt="info"
                      width="20px"
                      height="20px"
                      style={{ verticalAlign: "middle" }}
                    /> */}
                  </div>
                  <Grid
                    container
                    display="flex"
                    direction="row"
                    justifyContent="flex-start"
                  >
                    {sdgs.map((image, index) => (
                      <div style={{ paddingRight: "10px" }} key={index}>
                        <a href={image.link}>
                          <img
                            src={image.icon}
                            alt={image.name}
                            width="48"
                            height="48"
                            sx={{
                              borderRadius: "2px",
                            }}
                          />
                        </a>
                      </div>
                    ))}
                  </Grid>
                </Grid>
              )}
            </Box>
            <Box sx={{
                width: compactProjectDetail ? "100%" : "50%",
                padding: compactProjectDetail ? "2rem 1rem 2rem 3rem" : "2rem 1rem",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
            }}>
              {isLoadingAudio === true ? (
                // Skeleton loading UI while audio is loading
                <Skeleton variant="rect" width={400} height={200} />
              ) : selectedTrack?.length > 0 ? (
                <Grid item className="my-tenth-step">
                  <div style={{ display: "flex", alignItems: "center" }} >
                    <Typography variant="h5" style={{ marginRight: ".5rem" }}>
                      Listen to this Area
                    </Typography>
                    <img
                      src={infoPoupIcon}
                      alt="info"
                      width="20px"
                      height="20px"
                      style={{ verticalAlign: "middle" }}
                    />
                  </div>
                  <div>
                    {/* Actual waveform component once audio is loaded */}
                    <Waveform tracks={selectedTrack} />
                  </div>
                </Grid>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }} className="my-tenth-step">
                  <img
                    src={infoPoupIcon}
                    alt="info"
                    width="20px"
                    height="20px"
                    style={{
                      verticalAlign: "middle",
                      marginRight: ".5rem",
                    }}
                  />
                  <Typography variant="subtitle1" sx={{ color: "#62636" }}>
                    No audio data available
                  </Typography>
                </div>
              )}
            </Box>
          </Box>

          {/* Row 3 */}
          <Box sx={{ display: !compactProjectDetail ? 'flex' : 'block' }}>
            <Box
              className="my-eleventh-step"
              style={{
                width: compactProjectDetail ? "100%" : "50%",
                borderRight: "1px solid #D8D5CC",
                borderBottom: compactProjectDetail ? "1px solid rgba(224, 224, 224, 1)" : "none",
                padding: "1rem 1rem 2rem 3rem",
              }}
            >
              <div style={{ display: "flex", alignItems: "center", padding: "0.5rem 0rem" }}>
                <Typography variant="h5" style={{ marginRight: ".5rem" }}>
                  Images
                </Typography>
                <img
                  src={infoPoupIcon}
                  alt="info"
                  width="20px"
                  height="20px"
                  style={{ verticalAlign: "middle" }}
                />
              </div>
              {wildlife_visual_resources?.length > 0 && (
                <ProjectDetailCarousel
                  country={
                    countries && countries.length > 0
                      ? countries.length === 1
                        ? ` ${countries[0]}`
                        : countries.map((country, index) => (
                            <span key={index} style={{ fontWeight: "300" }}>
                              {index === 0 ? country : `, ${country}`}
                            </span>
                          ))
                      : countries && countries.length === 0
                      ? "No country available"
                      : null
                  }
                  wildlife_visual_resources={wildlife_visual_resources}
                />
              )}
            </Box>
            <Box
              sx={{
                width: compactProjectDetail ? "100%" : "50%",
                padding: compactProjectDetail ? "2rem 1rem 2rem 3rem" : "2rem 1rem",
              }}
            >
              <div>
                <Typography
                  style={{
                    fontFamily: "MADE TOMMY",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    color: "#2B2B2B",
                  }}
                  marginBottom={4}
                >
                  {" "}
                  Project Sites
                </Typography>
                <div style={{ display: "none", alignItems: "center" }}>
                  <img
                    src={infoPoupIcon}
                    alt="info"
                    width="20px"
                    height="20px"
                    style={{
                      verticalAlign: "middle",
                      marginRight: ".5rem",
                    }}
                  />
                  <Typography variant="subtitle1">
                    {" "}
                    No Project site data available
                  </Typography>
                </div>
                {sites && <ProjectSiteList sites={sites} />}
              </div>
            </Box>
          </Box>
    </TableContainer>
  );
};

export default ProjectDetailTable;
