import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid, Typography, IconButton } from "@mui/material";

import ChartCard from "ui-component/cards/ChartCard";
import { gridSpacing } from "store/constant";
import info from "../assets/images/icons/info.svg";
import share from "assets/images/icons/Share.svg";
import ToggleButtonComponent from "ui-component/ToggleButton";

const ChartLayout = ({ chart, title, groupChart, handleChartToggle, handleYLabelToggle, options, optionsUnit }) => {
  const [selected, setSelected] = useState(options?.length > 0 && options[0]?.value);
  const [selectedLabel, setSelectedLabel] = useState(optionsUnit?.length > 0 && optionsUnit[0]?.value);

  const handleToggle = (event, newValue) => {
    if (newValue !== null) {
      setSelected(newValue);
      handleChartToggle(newValue)
    }
  };

  const handleLabelToggle = (event, newValue) => {
    if (newValue !== null) {
      setSelectedLabel(newValue);
      handleYLabelToggle(newValue)
    }
  };


  return (
    <ChartCard>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
          <Grid>
            <Grid item>
              <Grid container direction="column" spacing={1}>
                {groupChart ? (
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item>
                        <ToggleButtonComponent
                          options={options}
                          value={selected}
                          onChange={handleToggle}
                          color="secondary"
                        />
                      </Grid>
                      <Grid item>
                        <ToggleButtonComponent
                          options={optionsUnit}
                          value={selectedLabel}
                          onChange={handleLabelToggle}
                          color="secondary"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <>
                  {
                    optionsUnit.length > 1 ? 
                      <Grid item xs={12}>
                      <Grid container justifyContent={"space-between"} alignItems="center">
                      <Grid item>
                        <Typography variant="h5" sx={{ color: "#2B2B2B" }}>
                          {title}
                        </Typography>
                      </Grid>
                        <Grid item>
                          <ToggleButtonComponent
                            options={optionsUnit}
                            value={selectedLabel}
                            onChange={handleLabelToggle}
                            color="secondary"
                          />
                        </Grid>
                      </Grid>
                    </Grid> 
                    :
                  <Grid item>
                    <Typography variant="h5" sx={{ color: "#2B2B2B" }}>
                      {title}
                    </Typography>
                  </Grid>
                  }
                  
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item display="none" alignItems="center">
              <IconButton>
                <img src={share} alt="share" />
              </IconButton>
              <IconButton>
                <img src={info} alt="info" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        {chart}
      </Grid>
    </ChartCard>
  );
};

ChartLayout.propTypes = {
  isLoading: PropTypes.bool,
  chart: PropTypes.node,
  title: PropTypes.string,
  groupChart: PropTypes.bool,
};

export default ChartLayout;
