import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';

const CustomToggleButton = styled(ToggleButton)({
  '&.Mui-selected, &.Mui-selected:hover': {
    color: '#164A1A',
    backgroundColor: '#d3d3d357',
  },
});

const ToggleButtonComponent = ({ options, value, onChange, color = 'primary', exclusive = true }) => {
  return (
    <ToggleButtonGroup
      color={color}
      value={value}
      exclusive={exclusive}
      onChange={onChange}
      aria-label="toggle button group"
    >
      {options.map((option) => (
        <CustomToggleButton key={option.value} value={option.value}>
          {option.label}
        </CustomToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default ToggleButtonComponent;
