import React, { useState, useEffect, useRef, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";
import { getTabsById } from "../../api";

import { 
  Grid,
  Typography,
  Paper,
  Box,
  useTheme,
  CircularProgress,
  useMediaQuery
} from "@mui/material";
import ProjectDetailNavTabs from "../ProjectDetailNavTabs";
import ProjectDetailTable from "./ProjectDetailTable";
import ProjectDetailAccordion from "./ProjectDetailAccordion";
import { useJoyride } from "utilities/JoyrideProvider";

import {
  fetchWithRetry,
  updateResource,
} from "component/ProjectDetailContent/ProjectDetailCarousel";

const BoxContainer = styled(Box)(() => ({
  marginTop: "2rem",
  marginBottom: "2rem",
}));

function ProjectDetailContent({ children, fetchedData, compactProjectDetail }) {

  const theme = useTheme();

  const { logout } = useAuth0();

  const token = localStorage.getItem("access_token");
  const [selectedTrack, _setSelectedTrack] = useState([]);
  const [isLoadingAudio, _setIsLoadingAudio] = useState(true);

  const [isLoadingTabs, setIsLoadingTabs] = useState(true);
  const [errorFetchingTabs, setErrorStatusTabs] = useState(false);
  const [tabsData, setTabsData] = useState([]);

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));

  const { joyrideState } = useJoyride();

  const selectedTrackRef = useRef(selectedTrack);
  const setSelectedTrack = (t) => {
    selectedTrackRef.current = t;
    _setSelectedTrack(t);
  };

  const isLoadingAudioRef = useRef(isLoadingAudio);
  const setIsLoadingAudio = (a) => {
    isLoadingAudioRef.current = a;
    _setIsLoadingAudio(a);
  };

  const { identifier, name, wildlife_audio_resources } = fetchedData;

  const handleLogout = useCallback(() => {
    localStorage.removeItem("access_token");
    Cookies.remove("access_token");
    logout({ logoutParams: { returnTo: window.location.origin } });
  },[logout]);

  const fetchAudioData = useCallback(async () => {
    setIsLoadingAudio(true);
    setSelectedTrack([
      ...wildlife_audio_resources.map((a) => {
        return { ...a, type: "audio", loading: true };
      }),
    ]);

    if (wildlife_audio_resources.length === 0 && isLoadingAudioRef.current)
      setIsLoadingAudio(false);
    try {
      for (const resource of wildlife_audio_resources) {
        let response;
        try {
          response = await fetchWithRetry(resource.access_url, token);
        } catch (error) {
          console.error(
            `Error fetching audio data: ${resource.access_url}:`,
            error,
          );
        }

        const blob = await response.blob();
        const audioURL = URL.createObjectURL(blob);

        setSelectedTrack((prevData) =>
          updateResource([...prevData], resource.identifier, {
            access_url: audioURL,
            loading: false,
          }),
        );
        if (isLoadingAudioRef.current) setIsLoadingAudio(false);
      }
    } catch (error) {
      setIsLoadingAudio(true);
      console.error("Error fetching image data:", error);
    }
  },[token,wildlife_audio_resources]);

  const fetchTabData = useCallback(async () => {
    setIsLoadingTabs(true);
    setErrorStatusTabs(false)
    try {
      const response = await getTabsById(identifier, token);
      if (response.status === 200) {
        setTabsData(response.data);
        setIsLoadingTabs(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        handleLogout();
      }
      setErrorStatusTabs(true)
      setIsLoadingTabs(false);
      console.error("Error fetching tabs data:", error);
    }
  },[handleLogout,identifier,token]);

  useEffect(() => {
    if (token && wildlife_audio_resources) {
      fetchTabData();
      fetchAudioData();
    }
  }, [fetchAudioData,fetchTabData,token,wildlife_audio_resources, joyrideState.stepIndex]);

  return (
    <BoxContainer
      className="Help-projectpage-general"
      sx={{
        marginTop: "0",
        height: "100%",
        overflow: ((isSmall || isMedium) || isMedium) ? "visible" : "auto",
        width: ((isSmall || isMedium) || isMedium) ? "100%" : (
          compactProjectDetail ? "calc(1/3 * 100%)" : "calc(2/3 * 100%)"
        ),
        position: "relative"
      }}
    >
      {fetchedData ? (
        <Paper
          sx={{
            height: "auto",
            borderRadius: "0",
            background: "#F9F5EB",
            marginTop: "0",
          }}
          id={identifier}
        >
          <Grid
            container
            display="flex"
            direction="row"
            justifyContent="space-between"
            align-items="flex-start"
            >
              {children}
              <Grid
                item
                xs={12}
                sx={{
                  paddingRight: (isSmall || isMedium) ? "1.5rem" : "90px",
                  paddingLeft: (isSmall || isMedium) ? "1.5rem" : "48px",
                  paddingTop: (isSmall || isMedium) ? "0.1rem" : "20px",
                  display: joyrideState.stepIndex >= 14 ? 'none' : '',
                }}
              >
                {name && (
                  <Typography
                    variant="h1"
                    sx={{
                      paddingBottom: "0.5rem",
                      color: "#2B2B2B",
                      width: (isSmall || isMedium) ? "90%" : "80%",
                      fontSize: (isSmall || isMedium) ? "38px" : "48px",
                    }}
                  >
                    {name}
                  </Typography>
                )}
              </Grid>

              {(isSmall || isMedium) ? <ProjectDetailAccordion
                fetchedData={fetchedData}
                isLoadingAudio={isLoadingAudio}
                selectedTrack={selectedTrack}
              /> :
          
                <ProjectDetailTable
                  fetchedData={fetchedData}
                  isLoadingAudio={isLoadingAudio}
                  selectedTrack={selectedTrack}
                  compactProjectDetail={compactProjectDetail}
                />
          
              }
            {isLoadingTabs ? (
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                height: "10rem",
                borderTop: "1px solid #e0e0e0"
              }}>
                <CircularProgress color="primary" />
              </div>
            ) : tabsData?.every(
                (item) =>
                  item.content.metrics.length === 0 &&
                  item.content.charts.length === 0,
              ) ? null : (

                <ProjectDetailNavTabs
                  fetchedData={fetchedData}
                  tabsData={tabsData}
                  isLoadingTabs={isLoadingTabs}
                  compactProjectDetail={compactProjectDetail}
                />
            )}
            {errorFetchingTabs &&
              <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                height: "10rem",
                borderTop: "1px solid #e0e0e0"
              }}>
                Failed to fetch metrics data for this project.
              </div>
            }
          </Grid>
        </Paper>
      ) : (
        // Render a message or component when data is undefined or failed to load
        <Typography variant="body2">Failed to load data.</Typography>
      )}
    </BoxContainer>
  );
}

export default ProjectDetailContent;