import React, { lazy } from 'react';
import Loadable from 'utilities/Loadable';
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  Chip,
  Skeleton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import ProjectDetailCarousel from "./ProjectDetailCarousel";
import Waveform from "component/ProjectPreviewContent/waveform";
import ProjectSiteList from "./ProjectSite";

import { AboutInfo } from "./commonContent";

import StatelliteIcon from "../../assets/images/icons/Satellite.svg";
import CameraIcon from "../../assets/images/icons/Photo.svg";
import SensorIcon from "../../assets/images/icons/Sensor.svg";
import infoPoupIcon from "../../assets/images/icons/infobuttonicon.svg";
import Pin from "../../assets/images/icons/Pin.svg";
import mapStroke from "../../assets/images/icons/mapstroke.svg";
import calender from "../../assets/images/icons/calender.svg";
import sizeimg from "../../assets/images/icons/sizeimg.svg";

const ProjectDetailMapComponent = Loadable(lazy(() => import('component/ProjectDetailMapComponent')));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  background: "#F9F5EB",
  padding: "0.8rem 0rem",
  border: `1px solid #D8D5CC`,
  borderLeft: "0px",
  borderRight: "0px",
  width: "100%",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    color: "#2B2B2B",
    fontSize: "18px",
    fontWeight: 500,
    padding: "0rem 1.3rem",
  }),
);

const AccordionDetails = styled((props) => <MuiAccordionDetails {...props} />)(
  ({ theme }) => ({
    padding: "0rem 1.3rem",
  }),
);

const ProjectDetailAccordion = ({
  fetchedData,
  isLoadingAudio,
  selectedTrack,
}) => {
  const {
    abstract,
    client,
    contact_email,
    countries,
    homepage,
    number_project_sites,
    sites,
    sdgs,
    site_area_formated_str,
    tags,
    wildlife_visual_resources,
    be_product,
    project_start,
  } = fetchedData;

  return (
    <>
      <div
        style={{
          paddingRight: "1.5rem",
          paddingLeft: "1.5rem",
        }}
      >
        <div
          style={{
            paddingTop: "1rem",
          }}
        >
          {countries && countries.length > 0 && (
            <Typography
              variant="subtitle1"
              sx={{
                paddingBottom: "0.5rem",
                fontWeight: 300,
                color: "#626364",
              }}
            >
              Country:
              {countries.length === 1
                ? ` ${countries[0]}`
                : countries.map((country, index) => (
                    <span key={index} style={{ fontWeight: "300" }}>
                      {index === 0 ? country : `, ${country}`}
                    </span>
                  ))}
            </Typography>
          )}

          {contact_email && (
            <Typography
              variant="subtitle1"
              sx={{
                paddingBottom: "0.5rem",
                fontWeight: 300,
                color: "#626364",
              }}
            >
              <>Contact Email: </>
              <span style={{ fontWeight: "400" }}>
                &nbsp; &nbsp; {homepage}
              </span>
            </Typography>
          )}

          {client && (
            <Typography
              variant="subtitle1"
              sx={{
                paddingBottom: "0.5rem",
                fontWeight: 300,
                color: "#626364",
              }}
            >
              <>Client:</> &nbsp; &nbsp;
              <a
                href={homepage}
                style={{
                  fontWeight: "300",
                  textDecoration: "none",
                  cursor: "pointer",
                  color: "#626364",
                }}
                onMouseEnter={(e) => {
                  e.target.style.textDecoration = "underline";
                }}
                onMouseLeave={(e) => {
                  e.target.style.textDecoration = "none";
                }}
              >
                {client}
              </a>
            </Typography>
          )}
        </div>
        <div
          style={{
            paddingBottom: "2rem",
          }}
        >
          <Grid
            item
            style={{
              display: "flex",
              gap: "15px",
              margin: "1rem 0rem",
              marginTop: "1rem",
              height: "auto",
            }}
          >
            <img src={StatelliteIcon} alt="StatelliteIcon" />
            <img src={SensorIcon} alt="StatelliteIcon" />
            <img src={CameraIcon} alt="StatelliteIcon" />
          </Grid>

          {tags && (
            <Grid
              item
              style={{
                display: "flex",
                gap: "8px",
                margin: "1rem 0rem",
                marginTop: "1rem",
                height: "auto",
              }}
            >
              {tags &&
                tags?.map((tag, index) => (
                  <Chip
                    key={index}
                    label={tag}
                    variant="outlined"
                    size="large"
                    sx={{
                      borderColor: "#1A5C1F",
                      color: "#1A5C1F",
                      borderRadius: "5px",
                    }}
                  />
                ))}
            </Grid>
          )}
        </div>
      </div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel-about"
        >
          About this project
        </AccordionSummary>
        <AccordionDetails>
          {abstract && (
            <Grid
              item
              sx={{
                paddingBottom: "1rem",
                height: "auto",
              }}
            >
              <Grid container>
                <div>
                  <AboutInfo img={sizeimg} paddingTop={"5px"}>
                    {site_area_formated_str}
                  </AboutInfo>
                  <AboutInfo img={Pin} paddingTop={"10px"}>
                    {number_project_sites} sites
                  </AboutInfo>
                </div>
                <div>
                  <AboutInfo img={calender} paddingTop={"5px"}>
                    Since {project_start.split('-')[0]}
                  </AboutInfo>
                  <AboutInfo img={mapStroke} paddingTop={"10px"}>
                    {be_product} sites
                  </AboutInfo>
                </div>
              </Grid>
              <Typography
                mb={2}
                mt={4}
                style={{
                  fontSize: "16px",
                  letterSpacing: "0px",
                  color: "#2B2B2B",
                  lineHeight: "19.84px",
                }}
              >
                {abstract}
              </Typography>
                <Typography
                  variant="body2"
                  mb={2}
                  style={{
                    color: "#2B2B2B",
                    letterSpacing: 0,
                    lineHeight: "19.84px",
                    fontWeight: 400,
                    fontFamily: `'Source Sans 3', sans-serif`,
                    fontStyle: "italic",
                    fontSize: "15px", //styleName: Large/Other/Citation;
                  }}
                >
                  – written by ‘{client}’
                </Typography>
            </Grid>
          )}

          {sdgs && sdgs.length > 0 && (
            <Grid item style={{ paddingBottom: "2rem" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    padding: "1rem 0rem",
                    marginRight: ".5rem",
                  }}
                >
                  Sustainable development goals
                </Typography>
              </div>
              <Grid
                container
                display="flex"
                direction="row"
                justifyContent="flex-start"
              >
                {sdgs.map((image, index) => (
                  <div style={{ paddingRight: "10px" }} key={index}>
                    <a href={image.link}>
                      <img
                        src={image.icon}
                        alt={image.name}
                        width="48"
                        height="48"
                        sx={{
                          borderRadius: "2px",
                        }}
                      />
                    </a>
                  </div>
                ))}
              </Grid>
            </Grid>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel-audio"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h5" style={{ marginRight: ".5rem" }}>
              Listen to this Area
            </Typography>
            <img
              src={infoPoupIcon}
              alt="info"
              width="20px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {isLoadingAudio === true ? (
            // Skeleton loading UI while audio is loading
            <Skeleton variant="rect" width={400} height={200} />
          ) : selectedTrack?.length > 0 ? (
            <Waveform tracks={selectedTrack} />
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={infoPoupIcon}
                alt="info"
                width="20px"
                height="20px"
                style={{
                  verticalAlign: "middle",
                  marginRight: ".5rem",
                }}
              />
              <Typography variant="subtitle1" sx={{ color: "#62636" }}>
                No audio data available
              </Typography>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel-images"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h5" style={{ marginRight: ".5rem" }}>
              Images
            </Typography>
            <img
              src={infoPoupIcon}
              alt="info"
              width="20px"
              height="20px"
              style={{ verticalAlign: "middle" }}
            />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          {wildlife_visual_resources?.length && (
            <ProjectDetailCarousel
              country={
                countries && countries.length > 0
                  ? countries.length === 1
                    ? ` ${countries[0]}`
                    : countries.map((country, index) => (
                        <span key={index} style={{ fontWeight: "300" }}>
                          {index === 0 ? country : `, ${country}`}
                        </span>
                      ))
                  : countries && countries.length === 0
                  ? "No country available"
                  : null
              }
              wildlife_visual_resources={wildlife_visual_resources}
            />
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel-project-sites"
        >
          Project Sites
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div style={{ display: "none", alignItems: "center" }}>
              <img
                src={infoPoupIcon}
                alt="info"
                width="20px"
                height="20px"
                style={{
                  verticalAlign: "middle",
                  marginRight: ".5rem",
                }}
              />
              <Typography variant="subtitle1">
                {" "}
                No Project site data available
              </Typography>
            </div>

            {sites && <ProjectSiteList sites={sites} />}
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{
        paddingBottom: "0"
      }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel-map"
          sx={{
            paddingBottom: "0.8rem"
          }}
        >
          Map
        </AccordionSummary>
        <AccordionDetails sx={{
          padding: "0rem"
        }}>
          {fetchedData ? (
            // Render ProjectDetailMapComponent when fetchedData is available
            <div style={{
              height: "496px"
            }}>
              <ProjectDetailMapComponent
                mapData={fetchedData}
                layerGeoJson={fetchedData.geometry}
              />
            </div>
          ) : (
            // Render error message or alternative content when fetchedData is null
            <Skeleton variant="rect" width="100%" height="100vh" />
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ProjectDetailAccordion;
