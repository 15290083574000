import React, { useState, useEffect, useRef, lazy } from "react";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { AppBar, Skeleton, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { useJoyride } from "utilities/JoyrideProvider";


import Loadable from "utilities/Loadable";
import Header from "component/Header";
import Footer from "component/Footer";
import MapProjectCard from 'component/MapProjectCard';
import MobileSearchBar from "component/MobileSearchBar";
import FilterComponent from 'component/Filter';
import { searchProjects, getProjectDataByView } from "../../api/index";
import defaultTeaserImage from 'assets/images/defaultImage.png'

import MarkerIcon from "assets/images/icons/large-marker.svg";
import MapIcon from "assets/images/icons/map-inactive.svg";

// dashboard routing
const MapComponent = Loadable(lazy(() => import("component/MapComponent")));

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { logout } = useAuth0();

  const [viewBookmarked, setViewMarked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewFilters, setViewFilters] = useState(false);
  const [openFilterAlert, setOpenFilterAlert] = useState(false);
  const mapContainerRef = useRef(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectsData, setProjectsData] = useState([]);
  const [filteredProjectsData, setFilteredProjectsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [alertMessage, setAlertMessage] = useState(''); // Add state for the alert message
  const [selectedCardIndex, setSelectedCardIndex] = useState(
    localStorage.getItem("selectedProject"),
  );
  const { setStepReadyState } = useJoyride();
  const [searchTerm, setSearchTerm] = useState("");
  const [panelView, setPanelView] = useState("map");

  const token = localStorage.getItem("access_token");

  const MAX_RETRY_COUNT = 3; // Maximum number of retry attempts
  const RETRY_DELAY = 1000; // Delay between retry attempts in milliseconds

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    Cookies.remove("access_token");
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  // Function to check if the error is related to database connection
  const isDatabaseConnectionError = (error) => {
    return error.message.includes("ConnectionDoesNotExistError");
  };

  const fetchData = async (retryCount = 0) => {
    try {
      setLoading(true);
      try {
        const response = await getProjectDataByView(token, "map");
        if (
          response.detail === "not validated: Signature has expired" ||
          response.status === 401
        ) {
          console.error("Unauthorized access. Redirecting to login.");
          navigate("/");
          return;
        }
        const { data } = response.data;
        if (data.length === 0) {
          navigate('/info');
        } else {

          setProjectsData(data);
          setFilteredProjectsData(data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
        setLoading(false);

        // Retry if the error is related to database connection and retry count is less than maximum
        if (retryCount < MAX_RETRY_COUNT && isDatabaseConnectionError(error)) {
          await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY)); // Wait before retrying
          await fetchData(retryCount + 1); // Retry the fetch
        }
      }
    } catch (error) {
      console.error("Error:", error);
      if (error.response && error.response.status === 401) {
        handleLogout();
      }
      setLoading(false);
    }
  };


  const handleSearchChange = async (e) => {
    try {
      const newSearchTerm = e.target.value;
      setSearchTerm(newSearchTerm);

      if (!newSearchTerm.trim()) {
        setSelectedProject(null);
        localStorage.removeItem("selectedProject");
        // If the search term is empty, retrieve all projects
        setFilteredProjectsData(projectsData);
      } else {
        setLoading(true);
        const response = await searchProjects(newSearchTerm, token);
        const { data } = response?.data;
        // Convert object to array if necessary
        setFilteredProjectsData(data);
        setLoading(false);
      }
    } catch (error) {
      //console.error('Error:', error);
      if (error.response && error.response.status === 401) {
        handleLogout();
      }
      setLoading(false);
    }
  };

  const handleBackButtonClick = () => {
    setSearchTerm("");
    setLoading(false);
    setViewMarked(false);
    setSelectedProject(null);
    setViewFilters(false);
    localStorage.removeItem("selectedProject");
    setFilteredProjectsData(projectsData);
  };

  const handleBookMarkViews = async (e) => {
    setViewMarked(true);
    setPanelView('cards')
    setSelectedProject(null);
    localStorage.removeItem("selectedProject");

    const storedBookmarks = JSON.parse(localStorage.getItem("bookmarks"));

    if (storedBookmarks) {
      // Sort bookedmarked data in alphabetical order
      const sortedBookedMarkedData = storedBookmarks.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      setFilteredProjectsData(sortedBookedMarkedData);
    } else {
      setFilteredProjectsData([]);
    }
  };


  const handleCardClick = (uniqueId) => {
    setSelectedProject(null);
    setLoading(false);
    navigate(`/project/projectdetail/${uniqueId}`);

  }

  const handleFilterViews = async (e) => {
    try {
      setViewFilters(true);
      setPanelView("cards");
      setViewMarked(false);
      setFilteredData([]);
    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.status === 401) {
        handleLogout();
      }
      setViewFilters(false);
    }
  };

  const handleCloseFilterAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenFilterAlert(false);
  };
  const getAlertMessage = (value, showAlert) => {
    setAlertMessage(value);
    setOpenFilterAlert(showAlert);
  };

  const getFilteredData = async (data) => {
    try {
      setViewFilters(false);
      setViewMarked(false);
      setFilteredProjectsData(data);
      setFilteredData(data)
    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.status === 401) {
        handleLogout();
      }
      setViewFilters(false);
    }
  };

  const handlePanelView = () => {
    setPanelView(panelView === "map" ? "cards" : "map");
    setFilteredData([]);
  }

  const triggerTour = () => {
    localStorage.removeItem("selectedProject");
    setStepReadyState(true); // Notify Joyride that the step is ready
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          borderBottom: "1.5px solid #164A1A",
          boxShadow: "none",
          background: "#F9F5EB",
          padding: 0,
          top: 0,
          height: "56px",
          width: "100%",
          position: "sticky",
          zIndex: 99901,
        }}
      >
        <Header triggerTour={triggerTour} />
      </AppBar>

      {/* Change panel button */}
      <div
        onClick={handlePanelView}
        style={{
          background: "#F9F5EB",
          position: "fixed",
          transform: "translateY(-50%)",
          top: "70%",
          zIndex: 9999,
          padding: "1rem",
          borderTopRightRadius: "4px",
          borderBottomRightRadius: "4px",
        }}
      >
        <img src={panelView === "map" ? MarkerIcon : MapIcon} alt="map" />
      </div>

      <MobileSearchBar
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
        handleBackButtonClick={handleBackButtonClick}
        showBackButton={searchTerm !== "" || viewBookmarked || viewFilters}
        handleBookMarkViews={handleBookMarkViews}
        viewBookmarked={viewBookmarked}
        handleFilterViews={handleFilterViews}
        filteredData={filteredData}
        viewFilters={viewFilters}
      />
      {panelView === "map" && (
        <MapComponent
          searchResults={filteredProjectsData
            .map((item) => item.geometry_centroid.coordinates)
            .map(([x, y]) => [y, x])}
          mapContainerRef={mapContainerRef}
          selectedProject={selectedCardIndex}
          data={filteredProjectsData}
          handleCardClick={handleCardClick}
          geoJsonData={selectedProject ? selectedProject.geometry : null}
        >
        </MapComponent>
      )}

      {panelView === "cards" && (
        <>
          {loading ? (
            // Render skeletons when initialLoading is true
            <Skeleton variant="rect" width="100%" height="100vh" />
          ) : (
            <>
              <Box
                style={{
                  width: "324px",
                  margin: "auto",
                }}
              >
                {
                  !viewFilters ?
                    (
                      (filteredProjectsData && filteredProjectsData.length) ?
                        <>
                          {filteredProjectsData?.map((item, index) => (
                            <MapProjectCard
                              key={index}
                              active={item.identifier === setSelectedCardIndex}
                              onCardHover={() => { return null }}
                              onCardLeave={() => { return null }}
                              onCardClick={handleCardClick}
                              teaserImage={
                                item.teaser_image_resources?.length > 0
                                  ? item.teaser_image_resources[0].access_url
                                  : defaultTeaserImage
                              }
                              index={item.identifier || selectedCardIndex}
                              isPopoverOpen={false}
                              projectData={item}
                            />
                          ))}
                        </> :
                        <Box sx={{
                          minHeight: "calc(-142px + 100vh)",
                          color: '#a4a29f',
                          textAlign: 'center',
                          padding: '3rem',
                          lineHeight: 2
                        }}>
                          {viewBookmarked && "There are no bookmarked projects"}
                        </Box>


                    ) :
                    (
                      <FilterComponent getAlertMessage={getAlertMessage} getFilteredData={getFilteredData} />
                    )
                }
              </Box>
            </>
          )}

          {openFilterAlert && (
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              open={openFilterAlert}
              autoHideDuration={3000}
              onClose={handleCloseFilterAlert}
            >

              <Alert
                onClose={handleCloseFilterAlert}
                severity="success"
                variant="filled"
                sx={{ width: '50%', backgroundColor: '#EAFFD8', color: '#164A1A' }}
                action={<></>} // Hides the close icon
                iconMapping={{
                  success: <CheckCircleOutlineRoundedIcon fontSize="inherit" />,
                }}
              >
                {alertMessage}
              </Alert>
            </Snackbar>
          )
          }
        </>
      )}

      <Footer />
    </>
  );
};

export default MinimalLayout;
