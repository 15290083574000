import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import React from "react";

const LogoButtonRoot = styled(IconButton)(({ theme }) => ({
  root: {},
  '&:hover': {
    backgroundColor: 'transparent', // Remove the hover background color
  },
}));

const LogoImage = styled("img")({
  width: "70%",
  height: "auto",
  objectFit:"contain",
  marginRight: "2rem"
});

function LogoButton({
  alt,
  children: childrenProp,
  imgProps,
  src,
  ...props
}) {
  if (!(childrenProp || src)) {
    return null;
  }

  const children = childrenProp || (
    <LogoImage alt={alt || ""} src={src} />
  );

  return (
    <LogoButtonRoot
    disableRipple
    disableFocusRipple
    disableTouchRipple
      {...props}
      size="large"
    >
      {children}
    </LogoButtonRoot>
  );
}

LogoButton.propTypes = {
  alt: PropTypes.string,
  children: PropTypes.node,
  imgProps: PropTypes.shape({}),
  src: PropTypes.string,
};

export default LogoButton;
